<template>
  <div>
  <TosListVuetify serviceName="yard" entityName="handlingtypes" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
       <v-row>         
          <v-col cols="3">
            <v-text-field :label="tLabel('Code')"  v-model="slotProps.data.codeLike" :placeholder="tLabel('Code')+' like '" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
        <v-container fluid>
          <v-row>     
            <v-col>
              <v-text-field :label="tLabel('Code')"  v-model="slotProps.data.code" :rules="[rules.required]" :error-messages="slotProps.errors.code" />
            </v-col>               
            <v-col>
              <v-select :label="tLabel('Direction')"  v-model="slotProps.data.direction" :items="directions" :error-messages="slotProps.errors.direction" />
            </v-col>       
            <v-col>
              <v-select :label="tLabel('Transport Type')"  v-model="slotProps.data.transportType" :items="transportTypes" :error-messages="slotProps.errors.transportType" />
            </v-col>    
          </v-row>
          <v-row>            
            <v-col>
              <v-select :label="tLabel('Full/Empty')"  v-model="slotProps.data.fullEmpty" :items="fullEmpties" :error-messages="slotProps.errors.fullEmpty" />
            </v-col>
            <v-col>
              <v-select :label="tLabel('Count Type')"  v-model="slotProps.data.countType" :items="countTypes" :error-messages="slotProps.errors.countTypes" />
            </v-col>
            <v-col>
              <v-checkbox :label="tLabel('Dangerous')" v-model="slotProps.data.dangerous"  />        
            </v-col>
          </v-row>       
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '../../../../components/anagrafiche/TosListVuetify';
import FormatsMixins from "../../../../mixins/FormatsMixins";
import YardEnumMixin from "../../../../mixins/yard/EnumMixin";

export default {
  name: "HandlingTypes",
  data() {
    return {
      headers: [
        { text: "", value: "action" },
        // { text: "ID", value: "id" },
        { text: `${this.tLabel('Code')}`, value: "code" },
        { text: `${this.tLabel('Direction')}`, value: "direction" },
        { text: `${this.tLabel('Transport Type')}`, value: "transportType" },
        { text: `${this.tLabel('Full/Empty')}`, value: "fullEmpty" },
        { text: `${this.tLabel('Count Type')}`, value: "countType" },
        { text: `${this.tLabel('Dangerous')}`, value: "dangerous" }
      ],
    };
  },
  mounted() {

  },
  
  methods: {
    async prepareItem(data) {
    },
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins, YardEnumMixin],
};
</script>
